export const styles = theme => ({
  linkStyles: {
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.primary.contrastText,
      textDecoration: 'none',
      fontFamily: 'Roboto Condensed',
      fontSize: '1.5em',
    },
  },
  monplanLogo: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    fontFamily: "'Roboto Condensed', 'Arial Narrow', sans-serif",
    fontSize: '1.5em',
  },
});
