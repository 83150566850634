export const Strings = {
  SLOGAN: '',
  MODAL_WARNING_MESSAGE:
    'We have recently upgraded our software, so you may face issues with your current course plan. \n Please clear/delete your course plan if you encounter any problems.',
};

export const HomePageStrings = {
  CONTINUE_PLANNING:
    'You can continue planning your course by clicking the button below.',
};

export const CourseSelectStrings = {
  DISCLAIMER:
    'Please note that we currently only support undergraduate courses.',
};

export const SidebarStrings = {
  LOGIN_LEAD_GEN: 'Try our new accounts system by logging in',
};
