/**
 * Returns the year when students are (re)enrolling into their courses. If the
 * specified date is beyond mid-October, then it returns the year beyond that date.
 * Otherwise it returns the same year as the specified date.
 *
 * @author Eric Jiang, Saurabh Joshi
 * @param {Date} [specifiedDate] - If not specified, the function simply uses the current date.
 * @return {number} enrolmentYear
 */
const getEnrolmentYear = specifiedDate => {
  const date = specifiedDate || new Date();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (month > 10 || (month === 10 && day >= 15)) {
    return year + 1;
  }

  return year;
};
export default getEnrolmentYear