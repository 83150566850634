import { GET_UPLOAD_URL } from './data/adminUpload';
import CoreUtils from '../utils/CoreUtils';
import NetworkService from './NetworkService';
import axios from 'axios';

export default class AdminUploadService {
  static getUploadURL(fileName, fileType) {
    const requestURI = `${
      window.location.origin
    }/api/admin/uploads/uploadlink?fileName=${fileName}&fileType=${fileType}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('AdminUploadService.getUploadURL()', requestURI, 'GET');
      return CoreUtils.createMockRequest('resolve', GET_UPLOAD_URL, 3000);
    }
    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static uploadFileToGCS(
    signedURL,
    file,
    fileType = null,
    onUploadProgress = undefined,
  ) {
    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'AdminUploadService.uploadFileToGCS()',
        signedURL,
        'GET',
      );
      return CoreUtils.createMockRequest('resolve', '', 3000);
    }
    // we send a custom axios Request as fetch is really buggy
    return axios
      .put(signedURL, file, {
        headers: {
          'Content-Type': fileType,
        },
        onUploadProgress: onUploadProgress,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static processCSVFromGCS(gcsObjectName, processAs) {
    const requestURI = `${
      window.location.origin
    }/api/admin/uploads/confirm?gcsObjectName=${gcsObjectName}&processAs=${processAs}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'AdminUploadService.processCSVFromGCS()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        `Processing ${processAs}`,
        3000,
      );
    }
    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
}
