import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router';
import { Provider } from 'react-redux';

// Styling
import './styles/custom.css';
import './styles/monash.css';
import './styles/unit_modal.css';
import './index.css';

// PROVIDERS
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import { store, history, theme, routes } from './config';
import AnalyticsService from './services/AnalyticsService';
import { getGoogleAnalyticsTrackingCode } from './config';

// DATE TIME PICKER UTILS
import MomentUtils from '@date-io/moment';

const ReactGA = require('react-ga');

ReactGA.initialize(getGoogleAnalyticsTrackingCode(window.location.hostname));

AnalyticsService.getUserIP()
  .then(IPAddress => {
    ReactGA.ga(tracker => {
      const client_id = tracker.get('clientId');
      ReactGA.event({
        category: 'userIp',
        action: 'loadWebsite',
        label: `{${client_id}:${IPAddress}}`,
      });
    });
  })
  .catch(err => {
    console.error(err);
  });

/**
 * logPageView
 */
function logPageView() {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
}

render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router routes={routes} history={history} onUpdate={logPageView} />
      </MuiPickersUtilsProvider>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('app'),
);
