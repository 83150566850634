import React, { Component } from 'react';
import { detect } from 'detect-browser';

// MUI Icons
import { default as InfoIcon } from '@material-ui/icons/Warning';
import { default as CloseIcon } from '@material-ui/icons/Close';

/**
 * Browser Warning Component Gives Warnings Out to users regarding their browsers
 */
class BrowserWarning extends Component {
  /**
   * Constructor of Component
   */
  constructor() {
    super();
    this.state = {
      showIEWarning: false,
    };
  }

  /**
   * Post-Render
   */
  componentDidMount() {
    const browser = detect()
    if (browser && browser.name === 'ie') {
      this.setState({
        showIEWarning: true,
      });
    }
  }

  /**
   * Render
   */
  render() {
    const { showIEWarning } = this.state;
    return (
      <div>
        {showIEWarning && (
          <div
            style={{
              minWidth: '100%',
              background: '#d2e1f6',
              padding: '1em',
              zIndex: '99999999',
              fontSize: '14px',
            }}>
            <InfoIcon
              style={{
                float: 'left',
                marginRight: '1em',
                width: 16,
                height: 16,
              }}
            />
            MonPlan is better with{' '}
            <a
              href="https://chrome.google.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'blue' }}>
              Google Chrome
            </a>{' '}
            and Microsoft Edge. As some features may be disabled as it is
            incompatible with Internet Explorer. You can view info{' '}
            <a
              href="https://github.com/MonPlan/releases/wiki/Internet-Explorer-Disabled-Features"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'blue' }}>
              here
            </a>
            .
            <CloseIcon
              style={{
                float: 'right',
                width: 16,
                height: 16,
              }}
              onClick={() => {
                this.setState({ showIEWarning: false });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default BrowserWarning;
