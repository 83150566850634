import React from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

// Components
import SmallLoader from '../../Base/SmallLoader';

// MUI Components
import Button from '@material-ui/core/Button';

// MUI Icons
import FileIcon from '@material-ui/icons/Description';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DropFileIcon from '@material-ui/icons/SaveAlt';
import ErrorIcon from '@material-ui/icons/Error';
import OkIcon from '@material-ui/icons/CheckCircle';

// Constants
import FileDropInner from './FileDropInner';
import FileTypes from './FileTypes';
import constants from './constants';
import FileDropUtils from './FileDropUtils';
import styles from './styles';
class FileDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedFiles: [],
    };
  }

  onDrop = acceptedFiles => {
    const { maxNumberOfFiles } = this.props;
    // we get the max number of files from the stack
    this.setState({
      acceptedFiles: [...acceptedFiles.slice(0, maxNumberOfFiles)],
    });
  };

  renderInner = (isDragActive, isDragReject, allowedFileTypes) => {
    const { acceptedFiles } = this.state;
    const {
      hasSuccessfullyUpload,
      hasErrored,
      errorMessage,
      successMessage,
      isUploading,
      uploadingMessage,
    } = this.props;

    // We firstly do a check on if the file is successfully uploaded to GCS
    // as we do a check if there is a more than 0 accepted files
    if (hasSuccessfullyUpload) {
      return (
        <FileDropInner
          icon={
            <OkIcon
              style={styles.IconStyle(constants.DEFAULTS.colors.OK_COLOR)}
            />
          }
          message={successMessage || constants.DEFAULTS.messages.success}
        />
      );
    } else if (isUploading) {
      return <SmallLoader message={uploadingMessage} />;
    } else if (hasErrored) {
      return (
        <FileDropInner
          icon={
            <ErrorIcon
              style={{
                fontSize: '3em',
                color: constants.DEFAULTS.colors.ERROR_COLOR,
              }}
            />
          }
          message={errorMessage || constants.DEFAULTS.messages.uploadError}
        />
      );
    } else if (acceptedFiles.length > 0) {
      return (
        <FileDropInner
          icon={
            <OkIcon
              style={styles.IconStyle(constants.DEFAULTS.colors.OK_COLOR)}
            />
          }
          message={acceptedFiles.map((acceptedFile, index) => {
            return (
              <div
                key={`${index}_${acceptedFile.name}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 2,
                  marginTop: 5,
                }}>
                <FileIcon />
                {acceptedFile.name}
              </div>
            );
          })}
        />
      );
    }
      if (isDragReject) {
        return (
          <FileDropInner
            icon={
              <ErrorIcon
                style={{
                  fontSize: '3em',
                  color: constants.DEFAULTS.colors.ERROR_COLOR,
                }}
              />
            }
            message={`
            File type is not allowed. ${allowedFileTypes.description} are
            allowed to be uploaded.`}
          />
        );
      }
      if (isDragActive) {
        return (
          <FileDropInner
            icon={<DropFileIcon style={{ fontSize: '3em' }} />}
            message={constants.DEFAULTS.messages.activeDrag}
          />
        );
      }
        return (
          <FileDropInner
            icon={<UploadIcon style={{ fontSize: '3em' }} />}
            message={constants.DEFAULTS.messages.emptyState(
              allowedFileTypes.description,
            )}
          />
        );


  };

  render() {
    const { acceptedFiles } = this.state;
    const {
      maxFileSize,
      allowedFileTypes,
      onSubmit,
      submitButtonLabel,
      disableUploadButton,
      isUploading,
      hideActionButtonGroup,
      hasSuccessfullyUpload,
      hasErrored,
    } = this.props;
    return (
      <div>
        <Dropzone
          onDrop={this.onDrop}
          accept={allowedFileTypes.allowedTypes}
          maxSize={FileDropUtils.convertMbtoBytes(maxFileSize)}
          disableClick={acceptedFiles.length > 0}>
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
            const dragZoneStyles = FileDropUtils.getStyles(
              styles,
              isDragActive,
              isDragReject,
              hasErrored,
              hasSuccessfullyUpload,
            );
            return (
              <div
                {...getRootProps()}
                className={classNames('dropzone', {
                  'dropzone--isActive': isDragActive,
                })}
                style={dragZoneStyles}>
                <input {...getInputProps()} />
                {this.renderInner(isDragActive, isDragReject, allowedFileTypes)}
              </div>
            );
          }}
        </Dropzone>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }} />
          {!hideActionButtonGroup && (
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.setState({ acceptedFiles: [] })}
                style={{ marginLeft: 5, marginRight: 5 }}
                disabled={isUploading || acceptedFiles.length === 0}>
                Clear
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => onSubmit(this.state.acceptedFiles)}
                style={{ marginLeft: 5, marginRight: 5 }}
                disabled={
                  disableUploadButton ||
                  isUploading ||
                  acceptedFiles.length === 0
                }>
                {submitButtonLabel ||
                  constants.DEFAULTS.messages.submitButtonLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

FileDrop.defaultProps = {
  maxFileSize: 20,
  maxNumberOfFiles: 1,
  allowedFileTypes: FileTypes.FILES,
  onSubmit: () => window.alert('Submit!'),
  disableUploadButton: false,
};

FileDrop.propTypes = {
  /* The maximum file size in MB*/
  maxFileSize: PropTypes.number,
  maxNumberOfFiles: PropTypes.number,
  allowedFileTypes: PropTypes.shape({
    allowedTypes: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  disableUploadButton: PropTypes.bool,
  successMessage: PropTypes.string,
};

export default FileDrop;
